import React, { useState } from "react";
import { Transition } from 'react-transition-group';
import WPLink from "../wpLink";
import FixedImage from "../fixedImage";
import { Link } from "gatsby";
import styles from "./styles.module.scss";


const MobileNav = ({mainNavigationSettings, menuIcon, closeIcon, services, arrowBlue, hideMobileCtaButton = false, locations, dentists }) => {
	const {
		showLocationsInMenu,
		showServicesInMenu,
		showTeamInMenu,
		teamMenuTitle,
		servicesMenuTitle,
		navCta,
		links,
		mobileNavLinks,
		locationsMenuTitle,
		mobileNavigationLogo
	} = mainNavigationSettings;

	const [ navOpen, setNavOpen ] = useState(false);
	const [ servicesOpen, setServicesOpen ] = useState(false);
	const [ locationsOpen, setLocationsOpen ] = useState(false);
	const [ teamOpen, setTeamOpen ] = useState(false);

	const toggleNav = () => {
		const toggled = !navOpen;
		setNavOpen(toggled);
	};

	const closeNav = () => {
		setNavOpen(false);
	};

	const toggleServices = () => {
		const toggled = !servicesOpen;
		setServicesOpen(toggled);
	};

	const toggleLocations = () => {
		const toggled = !locationsOpen;
		setLocationsOpen(toggled);
	};

	const toggleTeam = () => {
		const toggled = !teamOpen;
		setTeamOpen(toggled);
	};

	const containerTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 1,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	 const shroudTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 0.3,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	 const accordionTransitionStyles = {
		entering: { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
		entered:  { 
			opacity: "1",
			margin: "0 0 20px 0",
			maxHeight: "100px",
		},
		exiting:  { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
		exited:  { 
			opacity: "0",
			margin: "0 0 0px 0",
			maxHeight: "0",
		},
 	};

  	return (
		<nav className={styles.mobilenav} role="navigation">
			<Transition in={navOpen} timeout={250} mountOnEnter unmountOnExit>
				{(state) => (
					<>
						<div role="button" aria-label="close mobile menu" className={styles.shroud} style={{...shroudTransitionStyles[state]}} onClick={() => { closeNav(); }} onKeyDown={(event) => { if (event.keycode === 13){ closeNav(); }}} tabIndex={0}></div>
						<div className={styles.top} style={{...containerTransitionStyles[state]}}>
							<div className={styles.logosection}>
								<Link to="/" className={styles.logolink}>
									{ mobileNavigationLogo && <FixedImage image={mobileNavigationLogo} className={styles.logo} /> }
								</Link>
							</div>
							<div className={styles.middle}>
								{
									showServicesInMenu && (
										<>
											<button className={servicesOpen ? styles.navlisttoggleactive : styles.navlisttoggle} onClick={()=>{ toggleServices(); }}>{servicesMenuTitle ? servicesMenuTitle : "Services" }</button>
											<Transition in={servicesOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styles.navlist}>
														{services && services.map( (service, index) => {
															return (
																<li key={`servicelink-${service.id}`} className={styles.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styles.icon} image={arrowBlue} />
																	<Link to={service.link} className={styles.link}  dangerouslySetInnerHTML={{__html: service.title}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									showLocationsInMenu && (
										<>
											<button className={locationsOpen ? styles.navlisttoggleactive : styles.navlisttoggle} onClick={()=>{ toggleLocations(); }}>{locationsMenuTitle ? locationsMenuTitle : "Locations" }</button>
											<Transition in={locationsOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styles.navlist}>
														{locations && locations.map( (location, index) => {
															return (
																<li key={`locationlink-${location.id}`} className={styles.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styles.icon} image={arrowBlue} />
																	<Link to={location.link} className={styles.link} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									showTeamInMenu && (
										<>
											<button className={teamOpen ? styles.navlisttoggleactive : styles.navlisttoggle} onClick={()=>{ toggleTeam(); }}>{teamMenuTitle ? teamMenuTitle : "Our Team" }</button>
											<Transition in={teamOpen} timeout={250} mountOnEnter unmountOnExit>
												{(state) => (
													<ul className={styles.navlist}>
														{dentists && dentists.map( (dentist, index) => {
															return (
																<li key={`dentistlink-${dentist.id}`} className={styles.navlistitem} style={{...accordionTransitionStyles[state]}}>
																	<FixedImage passedClass={styles.icon} image={arrowBlue} />
																	<Link to={dentist.link} className={styles.link}  dangerouslySetInnerHTML={{__html: dentist.title}}></Link>
																</li>
															)
														})}
													</ul>
												)}
											</Transition>
										</>
									)
								}
								{
									links && links.map( (link, index) => {
										return <WPLink key={`extralink-${index}`} link={link.link} passedClass={styles.linkstyles} />;
									})
								}
							</div>
							{
								mobileNavLinks && (
									<div className={styles.secondarylinks}>
										<ul className={styles.navlist}>
											{mobileNavLinks.map( (link, index) => {
												return (
													<li key={`secondlink-${index}`} className={styles.navlistitem}>
														<WPLink link={link.mobileLinkItem} passedClass={styles.link} />
													</li>
												)
											})}
										</ul>				
									</div>
								)
							}
						</div>
					</>
				)}
			</Transition>
			<div className={hideMobileCtaButton ? styles.bottomauto : styles.bottom}>
				{ !hideMobileCtaButton && <WPLink link={navCta} passedClass={styles.cta} /> }
				<button className={styles.togglemenu} onClick={()=>{toggleNav();}}>{ navOpen ? "Close" : "Menu"}{navOpen ? <FixedImage image={closeIcon} passedClass={styles.close} /> : <FixedImage image={menuIcon} passedClass={styles.menu} /> }</button>
			</div>
		</nav>
	);
}

export default MobileNav;
