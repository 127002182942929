import React, { useState, useEffect, useRef } from "react";
import NearestLocation from "../nearestLocation";
import NearestDentist from "../nearestDentist";
import WPLink from "../wpLink";
import Submenu from "../submenu";
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import { Link } from "gatsby";
import styles from "./styles.module.scss";
import { list } from "postcss";


const DesktopNav = ({mainNavigationSettings, isHomepage, hideCtaButton, services, arrowBlue, locations, dentists, arrowRedLocal, arrowGrayLocal, locationsPage = null, teamPage = null, headerNavHasDarkTheme = false}) => {
	const {
		showLocationsInMenu,
		showServicesInMenu,
		showTeamInMenu,
		servicesMegamenu: {
		  featuredServices
		},
		servicesMenuTitle,
		navCta,
		links,
		locationsMenuTitle,
		teamMenuTitle,
	} = mainNavigationSettings;

	const [ servicesOpen, setServicesOpen ] = useState(false);
	const [ locationsOpen, setLocationsOpen ] = useState(false);
	const [ teamOpen, setTeamOpen ] = useState(false);

	const [ servicesLinkActive, setServicesLinkActive ] = useState(false);
	const [ locationsLinkActive, setLocationsLinkActive ] = useState(false);
	const [ teamLinkActive, setTeamLinkActive ] = useState(false);


	const [ showMoreServicesIndicator, setShowMoreServicesIndicator ] = useState(false);
	const [ showMoreLocationsIndicator, setShowMoreLocationsIndicator ] = useState(false);
	const [ showMoreDentistsIndicator, setShowMoreDentistsIndicator ] = useState(false);
	const [ megaMenuScrolled, setMegaMenuScrolled ] = useState(false);
	const [ megaMenuManuallyScrolled, setMegaMenuManuallyScrolled ] = useState(false);


	// Megamenu list scroll btn
	const servicesMenuList = useRef(null);
	const locationsMenuList = useRef(null);
	const dentistsMenuList = useRef(null);
	
	const lastElementInServicesList = useRef();
	const lastElementInLocationsList = useRef();
	const lastElementInDentistsList = useRef();

	const scrollToLast = (listName) => {
		if(!megaMenuScrolled) {
			if(listName === 'services') {
				if( lastElementInServicesList && lastElementInServicesList.current ){
					lastElementInServicesList.current.scrollIntoView({ 
						behavior: "smooth", 
						block: "nearest"
					});
					setMegaMenuScrolled(true);
					setShowMoreServicesIndicator(false);
				}
			} else if (listName === 'locations') {
				if( lastElementInLocationsList && lastElementInLocationsList.current ){
					lastElementInLocationsList.current.scrollIntoView({ 
						behavior: "smooth", 
						block: "nearest"
					});
					setMegaMenuScrolled(true);
					setShowMoreLocationsIndicator(false);
				}
			} else if(listName === 'dentists') {
				if( lastElementInDentistsList && lastElementInDentistsList.current ){
					lastElementInDentistsList.current.scrollIntoView({ 
						behavior: "smooth", 
						block: "nearest"
					});
					setMegaMenuScrolled(true);
					setShowMoreDentistsIndicator(false);
				}
			}
		}
	};

	const userIsManuallyScrollingMegaMenu = () => {
		setMegaMenuManuallyScrolled(true);
	}

	useEffect(() => {

		const moreServicesIndicator = () => {	
			if( servicesMenuList && servicesMenuList.current && servicesMenuList.current.parentElement ){
				if( servicesMenuList.current.scrollHeight > servicesMenuList.current.parentElement.offsetHeight ){
					setShowMoreServicesIndicator(true);
				} else {
					setShowMoreServicesIndicator(false);
				}
			}
		};
		if( servicesMenuList.current && !megaMenuScrolled){
			moreServicesIndicator();
		}

		const moreLocationsIndicator = () => {
			if( locationsMenuList && locationsMenuList.current && locationsMenuList.current.parentElement ){
				if( locationsMenuList.current.scrollHeight > locationsMenuList.current.parentElement.offsetHeight ){
					setShowMoreLocationsIndicator(true);
				} else {
					setShowMoreLocationsIndicator(false);
				}
			}
		};
		if( locationsMenuList.current && !megaMenuScrolled){
			moreLocationsIndicator();
		}

		const moreDentistsIndicator = () => {
			if( dentistsMenuList && dentistsMenuList.current && dentistsMenuList.current.parentElement ){
				if( dentistsMenuList.current.scrollHeight > dentistsMenuList.current.parentElement.offsetHeight ){
					setShowMoreDentistsIndicator(true);
				} else {
					setShowMoreDentistsIndicator(false);
				}
			}
		};
		if( dentistsMenuList.current && !megaMenuScrolled){
			moreDentistsIndicator();
		}
	});


	const toggleServices = () => {
		const toggled = !servicesOpen;
		setMegaMenuManuallyScrolled(false);
		setMegaMenuScrolled(false);
		setLocationsOpen(false);
		setTeamOpen(false);
		setServicesOpen(toggled);
	};
	
	const toggleLocations = () => {
		const toggled = !locationsOpen;
		setMegaMenuManuallyScrolled(false);
		setMegaMenuScrolled(false);
		setServicesOpen(false);
		setTeamOpen(false);
		setLocationsOpen(toggled);
	};
	
	const toggleTeam = () => {
		const toggled = !teamOpen;
		setMegaMenuManuallyScrolled(false);
		setMegaMenuScrolled(false);
		setServicesOpen(false);
		setLocationsOpen(false);
		setTeamOpen(toggled);
	};

	useEffect( () => {
		const setActiveLinks = () => {
			if( window.location.pathname.includes("/location/") || (locationsPage !== null && locationsPage.nodes.length > 0 && window.location.pathname.includes(locationsPage.nodes[0].link) ) ){
				setLocationsLinkActive(true);
			}

			if( window.location.pathname.includes("/service/") ){
				setServicesLinkActive(true);
			}

			if( window.location.pathname.includes("/dentist/") || (teamPage !== null && teamPage.nodes.length > 0 && window.location.pathname.includes(teamPage.nodes[0].link) ) ){
				setTeamLinkActive(true);
			}
		};

		if( typeof window !== undefined ){
			setActiveLinks();
		}
	}, [locationsPage, teamPage]);

	const heroHeight = isHomepage ? 850 : 600

	// for nav
	const [isNavSticky, setIsNavSticky] = useState(false)
	const [isNavVisible, setIsNavVisible] = useState(true)

	useEffect (()=>{
		document.addEventListener("scroll", e => {
			let scrolled = document.scrollingElement.scrollTop;

			if (scrolled >= 75 && scrolled < heroHeight) {
				setIsNavVisible(false)
			} else {
				 setIsNavVisible(true)
			 }

			if (scrolled >= heroHeight){
			   setIsNavSticky(true)
			} else {
				setIsNavSticky(false)
			}
		})
	},[heroHeight])

  	return (
		<nav className={`${styles.DesktopNav} ${isNavSticky ? styles.sticky : ''} ${isNavVisible ? '' : styles.opaque}`} role="navigation">
			<ul className={`${styles.navlist} ${headerNavHasDarkTheme && 'darktheme'}`}>
				{ showServicesInMenu && (
					<li className={`${styles.navlistitem} navlistitem`}>
						<button 
							className={servicesOpen ? styles.togglelinkactive : servicesLinkActive ? styles.toggleLinkNavActive : styles.togglelink} 
							onClick={(event)=>{
								event.preventDefault();
								toggleServices();
						}}>{servicesMenuTitle}</button>
						<button aria-label="close modal" className={  servicesOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { toggleServices(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleServices(); }}} tabIndex={0}></button> 
						<Submenu showIf={servicesOpen} setInsuranceIsOpen={setServicesOpen} passedClass={styles.services}>
							<div className={styles.inner} onScroll={userIsManuallyScrollingMegaMenu}>
								<div className={styles.left}>
									<span className={styles.heading}>Featured Services</span>
									<div className={styles.servicescontainer}>
										{ featuredServices && featuredServices.map((service, index) => {
			
											return (
												<div key={`ftsrvc-'${service.id}`} className={styles.featuredservice}>
													{service.ACFService.serviceIcon && (
														<div className={styles.iconrow}>
															<div className={styles.iconcontainer}>
																<FluidImage image={service.ACFService.serviceIcon} passedClass={styles.icon} /> 
															</div>
														</div>
													)}
													<Link to={service.link} className={styles.title} dangerouslySetInnerHTML={{__html: service.title }}></Link>
													{ service.ACFService.briefDescription && <p dangerouslySetInnerHTML={{__html: service.ACFService.briefDescription}}></p>}
												</div>
											)
										})}
									</div>
								</div>
								<div className={styles.right}>
									<span className={styles.heading}>Find more services.</span>
									<ul className={styles.list} ref={servicesMenuList}>
										{ services && services.map((service, index) => {
											return (
												<li key={`lstsrvc-'${service.id}`} className={styles.listitem} ref={index === (services.length -1) ? lastElementInServicesList : null}>
													<Link to={service.link} className={styles.link}>
														<svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
															<g fill="none" fillRule="evenodd">
																<g className={styles.iconblue}>
																	<g>
																		<g>
																			<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
																		</g>
																	</g>
																</g>
															</g>
														</svg>
														<span className={styles.title} dangerouslySetInnerHTML={{__html: service.title }}></span>
													</Link>	
												</li>
											)
										})}
									</ul>
									{showMoreServicesIndicator && !megaMenuManuallyScrolled && (
										<button aria-label="Scroll down" className={styles.showmore} onClick={(event)=>{ scrollToLast('services'); }}>More services <FixedImage image={arrowGrayLocal} passedClass={styles.icon}></FixedImage></button>
									)}
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{ showLocationsInMenu && (
					<li className={`${styles.navlistitem} navlistitem`}>
						<button 
							className={locationsOpen ? styles.togglelinkactive : locationsLinkActive ? styles.toggleLinkNavActive : styles.togglelink} 
							onClick={(event)=>{
								event.preventDefault();
								toggleLocations();
						}}>{locationsMenuTitle}</button>
						<button aria-label="close modal" className={  locationsOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { toggleLocations(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleLocations(); }}} tabIndex={0}></button> 
						<Submenu showIf={locationsOpen} setInsuranceIsOpen={setLocationsOpen} passedClass={styles.locations}>
							<div className={styles.inner} onScroll={userIsManuallyScrollingMegaMenu}>
								<div className={styles.left}>
									<span className={styles.heading}>Featured Location</span>
									<NearestLocation locations={locations} arrowRedLocal={arrowRedLocal} arrowGrayLocal={arrowGrayLocal} />
								</div>
								<div className={styles.right}>
									<span className={styles.heading}>Find more locations.</span>
									<ul className={styles.list} ref={locationsMenuList}>
										{ locations && locations.map((location, index) => {
											return (
												<li key={`lstloc-'${location.id}`} className={styles.listitem} ref={index === (locations.length -1) ? lastElementInLocationsList : null}>
													<Link to={location.link} className={styles.link}>
														{/* <FixedImage passedClass={styles.icon} image={arrowBlue} /> */}
														<svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
															<g fill="none" fillRule="evenodd">
																<g className={styles.iconblue}>
																	<g>
																		<g>
																			<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
																		</g>
																	</g>
																</g>
															</g>
														</svg>
														<span className={styles.title} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></span>
													</Link>	
												</li>
											)
										})}
									</ul>
									{showMoreLocationsIndicator && !megaMenuManuallyScrolled && (
										<button aria-label="Scroll down" className={styles.showmore} onClick={(event)=>{ scrollToLast('locations'); }}>More locations <FixedImage image={arrowGrayLocal} passedClass={styles.icon}></FixedImage></button>
									)}
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{ showTeamInMenu && (
					<li className={`${styles.navlistitem} navlistitem`}>
						<button 
							className={teamOpen ? styles.togglelinkactive : teamLinkActive ? styles.toggleLinkNavActive : styles.togglelink } 
							onClick={(event)=>{
								event.preventDefault();
								toggleTeam();
						}}>{teamMenuTitle}</button>
						<button aria-label="close modal" className={  teamOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { toggleTeam(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleTeam(); }}} tabIndex={0}></button> 
						<Submenu showIf={teamOpen} setInsuranceIsOpen={setTeamOpen} passedClass={styles.team}>
							<div className={styles.inner} onScroll={userIsManuallyScrollingMegaMenu}>
								<div className={styles.left}>
									<span className={styles.heading}>Featured Team Member</span>
	
									<NearestDentist 
										locations={locations} 
										dentists={dentists} 
										arrowRedLocal={arrowRedLocal} 
										arrowGrayLocal={arrowGrayLocal} 
										inMegaMenu={true}
									/>
								</div>
								<div className={styles.right}>
									<span className={styles.heading}>Find more dentists.</span>
									<ul className={styles.list} ref={dentistsMenuList}>
										{ dentists && dentists.map((dentist, index) => {
											return (
												<li key={`lstdnt-'${dentist.id}`} className={styles.listitem} ref={index === (dentists.length -1) ? lastElementInDentistsList : null}>
													<Link to={dentist.link} className={styles.link}>
														<svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11">
															<g fill="none" fillRule="evenodd">
																<g className={styles.iconblue}>
																	<g>
																		<g>
																			<path d="M188.638 11.04l-2.44-2.452c-.336-.339-.882-.339-1.218 0-.337.339-.337.887 0 1.226l3.91 3.932c.04.04.085.076.132.108.02.013.04.022.06.033.03.016.058.034.089.047.025.01.05.016.077.024.028.009.055.02.084.025.044.009.089.012.133.013.012 0 .023.004.035.004.013 0 .026-.004.039-.004.043-.002.086-.004.129-.013.03-.006.059-.017.088-.027.025-.007.05-.013.073-.022.033-.014.064-.033.095-.05.018-.01.036-.018.053-.03.048-.032.093-.068.133-.109l3.91-3.931c.169-.17.253-.391.253-.613 0-.222-.084-.444-.253-.613-.336-.339-.882-.339-1.219 0l-2.439 2.453V5.867c0-.479-.386-.867-.862-.867s-.862.388-.862.867v5.174z" transform="translate(-535 -751) translate(35 45) translate(315 702) rotate(-90 189.5 9.5)"/>
																		</g>
																	</g>
																</g>
															</g>
														</svg>
														<span className={styles.title} dangerouslySetInnerHTML={{__html: dentist.title }}></span>
													</Link>	
												</li>
											)
										})}
									</ul>
									{showMoreDentistsIndicator && !megaMenuManuallyScrolled && (
										<button aria-label="Scroll down" className={styles.showmore} onClick={(event)=>{ scrollToLast('dentists'); }}>More dentists <FixedImage image={arrowGrayLocal} passedClass={styles.icon}></FixedImage></button>
									)}
								</div>
							</div>
						</Submenu>
					</li>
				)}
				{
					links && links.map( (link, index) => {
						return <li key={`${link.link.url}`} className={`${styles.navlistitem} navlistitem`}><WPLink key={`extralink-${index}`} link={link.link} passedClass={styles.link} passedActiveClass={styles.linkNavActive} /></li>;
					})
				}
				{
					navCta && (
						<li className={`${styles.navlistitemcta} navctabtn ${hideCtaButton && styles.ctahidden}`}><WPLink link={navCta} passedClass={styles.cta} /></li>
					)
				}
			</ul>
		</nav>
	);
}

export default DesktopNav;
