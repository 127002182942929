import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FluidImage from "../fluidImage";
import WPLink from "../wpLink";
import styles from "./styles.module.scss";
import ReviewRotator from "../../components/reviewRotator";
// SVG
import facebookIcon from '../../images/svg-icons/facebook-white.svg';
import instagramIcon from '../../images/svg-icons/instagram-white.svg';
import tiktokIcon from '../../images/svg-icons/tiktok-white.svg';



const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			options: wp {
				socialSection {
					ACFSocial {
						facebookUrl
						instagramUrl
						tiktokUrl
					}
				}
				footerSection {
					ACFFooterOptions {
						headingImageText
						headingImage {
							altText
							localFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
								extension
							}
						}
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
								extension
							}
						}
						reviews {
							right {
								selectedReviews {
									... on WpReview {
										id
										ACFReview {
											blurb
											location
											stars
										}
										title
									}
								}
							}
							backgroundImage {
								altText
								localFile {
									childImageSharp {
										fluid {
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
									extension
								}
							}
						}
						calloutLeft {
							footerCalloutLogo {
								altText
								localFile {
									childImageSharp {
										fluid(maxWidth: 289){
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
									extension
								}
							}
						}
						calloutRight {
							footerCalloutBlurb
							footerCalloutHeading
							footerCalloutLink {
								target
								title
								url
							}
						}
						footerMenu1Title
						footerMenu1 {
							link {
								target
								title
								url
							}
						}
						footerMenu2Title
						footerMenu2 {
							link {
								target
								title
								url
							}
						}
						footerMenu3Title
						footerMenu3 {
							link {
								target
								title
								url
							}
						}
						footerLocationsPageLink {
							target
							title
							url
						}
						footerMenuMobilePrimary {
							link {
								target
								title
								url
							}
            			}
						footerMenuMobileSecondary {
							link {
								target
								title
								url
							}
            			}
						copyrightText
					}
				}
			}
			locations: allWpLocation(sort: {fields: title}) {
				nodes {
					id
					title
					link
					ACFLocation {
						menuTitle
					}
					birdeyeLocation {
						location {
							city
							state
						}
					}
				}
			}
			services: allWpService(sort: {fields: title}) {
				nodes {
				  title
				  link
				  id
				}
			}
			redStar: file(name: {eq: "Star"}) {
				childImageSharp {
				  fixed(width: 20) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
		}
	`);

	const {
		options: {
			footerSection: {
				ACFFooterOptions: {
					headingImageText,
					headingImage,
					backgroundImage,
					footerMenu1Title,
					footerMenu1,
					footerMenu2Title,
					footerMenu2,
					footerMenu3Title,
					footerMenu3,
					footerMenuMobilePrimary,
					footerMenuMobileSecondary,
					footerLocationsPageLink,
					reviews: {
						right: {
							selectedReviews
						},
						backgroundImage: reviewsBackgroundImage 
					},
					calloutLeft: {
						footerCalloutLogo
					},
					calloutRight: {
						footerCalloutBlurb,
						footerCalloutHeading,
						footerCalloutLink,
					},
					copyrightText
				}
			},
			socialSection: {
				ACFSocial: {
					facebookUrl,
					instagramUrl,
					tiktokUrl,
				}
			}
		},
		locations: {
			nodes: locations
		},
		services: {
			nodes: services
		},
		redStar,
	} = data;


	return (
		<>
		<section className={styles.reviews}>
			{ backgroundImage && <div className={styles.footerbg}><FluidImage image={backgroundImage} passedClass={styles.footerbg} /></div> }
			{ headingImage && <FluidImage image={headingImage} passedClass={styles.headingimage} /> }
			<div className={styles.inner}>
				{ reviewsBackgroundImage && <FluidImage image={reviewsBackgroundImage} passedClass={styles.imgleft} /> }
				{headingImageText ? <h3 className={styles.headingImageText}>{headingImageText}</h3> : <h3 className={styles.headingImageText}>noo</h3>}
				{ selectedReviews && <ReviewRotator reviews={selectedReviews} redStar={redStar} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in" /> }
			</div>
		</section>
		<footer className={styles.Footer}>
			<div className={styles.inner}>			
				 <div className={styles.callout} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
					<div className={styles.left}>
						{ footerCalloutLogo && <Link className={styles.logolink} to="/"><FluidImage image={footerCalloutLogo} passedClass={styles.logo} /></Link> }
						{ (facebookUrl || instagramUrl || tiktokUrl) && (
							<div className={styles.social}>
								{ facebookUrl && <a href={facebookUrl} target="_blank" rel="noopener noreferrer"><span>Facebook</span><img src={facebookIcon} alt="facebook" loading="lazy" /></a> }
								{ instagramUrl && <a href={instagramUrl} target="_blank" rel="noopener noreferrer"><span>Instagram</span><img src={instagramIcon} alt="instagram" loading="lazy" /></a> }
								{ tiktokUrl && <a href={tiktokUrl} target="_blank" rel="noopener noreferrer"><span>TikTok</span><img src={tiktokIcon} alt="TikTok" loading="lazy" /></a> }
							</div>
						) }
					</div>
					<div className={styles.right}>
						{ footerCalloutHeading && <h3 className={styles.heading}>{footerCalloutHeading}</h3> }
						{ footerCalloutBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: footerCalloutBlurb}}></div> }
						{ footerCalloutLink && <WPLink link={footerCalloutLink} passedClass={styles.link} /> }
					</div>
				</div> 

				<div className={styles.menus} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
					
					<div className={styles.desktopmenus}>

						<div className={styles.services}>
							{ services.length > 0 && (
								<ul className={styles.list}>
									<li className={styles.listitemmain}><h4 className={styles.link}>Services</h4></li>
									{ services.map( (service, _index) => {
										return (
											<li key={service.id} className={styles.listitemsecondary}>
												<Link to={service.link} className={styles.link} dangerouslySetInnerHTML={{__html: service.title}} />
											</li>
										);
									})}
								</ul>
							)}
						</div>
						<div className={styles.locations}>
							{ locations.length > 0 && ( 
								<ul className={styles.list}>
									{ footerLocationsPageLink && <li className={styles.listitemmain}><h4><WPLink link={footerLocationsPageLink} passedClass={styles.link} /></h4></li> }
									{ locations.map( (location, _index) => {
										return (
											<li key={location.id} className={styles.listitemsecondary}>
												<Link to={location.link} className={styles.link} dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}} />
											</li>
										);
									})}
								</ul>
							)}
						</div>
						
						{ footerMenu1 && (
							<div className={styles.footermenu}>
								<nav className={styles.nav}>
									{footerMenu1Title && <h4 className={styles.listitemmain}>{footerMenu1Title}</h4>}
									<ul className={styles.list}>
										{ footerMenu1.map( (menuitem, index) => {
											return (
												<li key={index} className={styles.listitemsecondary}>
													<WPLink link={menuitem.link} passedClass={styles.link} />
												</li>
											);
										})}
									</ul>
								</nav>
							</div>
						)}

						{ footerMenu2 && (
							<div className={styles.footermenu}>
								<nav className={styles.nav}>
									{footerMenu2Title && <h4 className={styles.listitemmain}>{footerMenu2Title}</h4>}
									<ul className={styles.list}>
										{ footerMenu2.map( (menuitem, index) => {
											return (
												<li key={index} className={styles.listitemsecondary}>
													<WPLink link={menuitem.link} passedClass={styles.link} />
												</li>
											);
										})}
									</ul>
								</nav>
							</div>
						)}

						{ footerMenu3 && (
							<div className={styles.footermenu}>
								<nav className={styles.nav}>
									{footerMenu3Title && <h4 className={styles.listitemmain}>{footerMenu3Title}</h4>}
									<ul className={styles.list}>
										{ footerMenu1.map( (menuitem, index) => {
											return (
												<li key={index} className={styles.listitemsecondary}>
													<WPLink link={menuitem.link} passedClass={styles.link} />
												</li>
											);
										})}
									</ul>
								</nav>
							</div>
						)}
					</div>

					<div className={styles.mobilemenus}>

						{/* Tablet & Mobile Menus */}
						{ footerMenuMobilePrimary && (
							<div className={`${styles.mobilemenu} ${styles.primary}`} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								<nav className={styles.nav}>
									<ul className={styles.list}>
										{ footerMenuMobilePrimary.map( (menuitem, index) => {
											return (
												<li key={index} className={styles.listitemsecondary}>
													<WPLink link={menuitem.link} passedClass={styles.link} />
												</li>
											);
										})}
									</ul>
								</nav>
							</div>
						)}
						{ footerMenuMobileSecondary && (
							<div className={`${styles.mobilemenu} ${styles.secondary}`} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
								<nav className={styles.nav}>
									<ul className={styles.list}>
										{ footerMenuMobileSecondary.map( (menuitem, index) => {
											return (
												<li key={index} className={styles.listitemsecondary}>
													<WPLink link={menuitem.link} passedClass={styles.link} />
												</li>
											);
										})}
									</ul>
								</nav>
							</div>
						)}
					</div>
				
				</div>

				<div className="be-ix-link-block"></div>	

				<div className={styles.copyright}>{new Date().getFullYear()} {copyrightText ? copyrightText : '&copy; All Rights Reserved'}</div>
			</div>
		</footer>
		</>
	);
};

export default Footer;
