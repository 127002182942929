import React, { useState, useEffect } from "react";
import { Transition } from 'react-transition-group';
import FixedImage from "../fixedImage";
import styles from "./styles.module.scss";
// SVG
import quotationIcon from '../../images/svg-icons/quotation-icon.svg';


const ReviewRotator = ({ reviews, redStar }) => {
	const [ reviewIndexMax ] = useState(reviews.length - 1);
	const [ currentReview, setCurrentReview ] = useState(0);
	const [ reviewHeight, setReviewHeight ] = useState(0);

	useEffect( () => {
		const rotation = setInterval(() => { 
			setCurrentReview(currentReview < reviewIndexMax ? currentReview + 1 : 0);
		}, 8000);

		return () => {
			clearInterval(rotation)
		}
	}, [currentReview, setCurrentReview, reviewIndexMax]);

	const reviewTransitionStyles = {
		maxHeight: reviewHeight === 0 ? 'initial': reviewHeight + "px",
		transition: 'max-height 0.6s ease-in-out',
		position: 'relative'
 	};

	 const reviewContainerTransitionStyles = {
		entering: { 
			opacity: 0,
		},
		entered:  { 
			opacity: 1,
		},
		exiting:  { 
			opacity: 0,
		},
		exited:  { 
			opacity: 0,
		},
 	};

	const reviewEnter = (node, isAppearing) => {
		setReviewHeight(node.offsetHeight);
	};


	return (
		<div className={styles.ReviewRotator}>
			<div className={styles.reviewcontainer} style={reviewTransitionStyles}>
				{ reviews && reviews.map( (review, index) => {
					return (
						<Transition key={review.id} in={currentReview === index} timeout={250} mountOnEnter unmountOnExit onEnter={(node,isAppearing)=>{ reviewEnter(node, isAppearing) }}>
							{(state) => (
								<div className={styles.review} style={{...reviewContainerTransitionStyles[state]}}>
									<>
										<div className={styles.rating}>
											{ [...Array(review.ACFReview.stars)].map((_val, index)=>{
												return <FixedImage key={`${review.id}star-${index}`} image={redStar} passedClass={styles.star} />;
											})}
										</div>
										{ review.ACFReview.blurb && <div className={styles.blurb}>
											{ review.ACFReview.blurb && (
												<>
													<img src={quotationIcon} className={styles.quotes} alt="" loading="lazy" />
													<div className={styles.content} dangerouslySetInnerHTML={{__html:review.ACFReview.blurb}}></div>
												</>
											)}
										</div> }
										{ review.title && <span className={styles.name} dangerouslySetInnerHTML={{__html: review.title}}></span> }
										{ review.ACFReview.location && <span className={styles.location} dangerouslySetInnerHTML={{__html: review.ACFReview.location}}></span> }
									</>
								</div>
							)}
						</Transition>
					)
				})}
			</div>
		</div>
	)
}

export default ReviewRotator;