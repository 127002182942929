import React from "react";
import Img from "gatsby-image";
import styles from "./styles.module.scss";

const FixedImage = ({ image, passedClass = styles.default, alternativeText = '' }) => {
	const hasLocalFile = image.localFile !== undefined;
	const childImageSharp = hasLocalFile ? image.localFile.childImageSharp : image.childImageSharp;
  	return childImageSharp && childImageSharp.fixed ? <Img className={passedClass} fixed={childImageSharp.fixed} alt={alternativeText} /> : null;
}

export default FixedImage;