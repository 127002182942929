import React from "react";
import Img from "gatsby-image";
import styles from "./styles.module.scss";

const FluidImage = ({ image, passedClass = styles.default, loading = 'lazy', alternativeText, fadeIn = true }) => {
	const hasLocalFile = image.localFile !== undefined;
	const altText = alternativeText ? alternativeText : image.altText ? image.altText : "";

	if( hasLocalFile && image.localFile.extension && image.localFile.extension === "svg"){
		return <div className={`${styles.svgcontainer} ${passedClass}`}><img className={styles.svg} src={image.localFile.publicURL} alt={altText} /></div>;
	} else {
		const childImageSharp = hasLocalFile ? image.localFile.childImageSharp : image.childImageSharp;
  		return childImageSharp && childImageSharp.fluid ? <Img className={passedClass} fluid={childImageSharp.fluid} alt={altText} loading={loading} fadeIn={fadeIn} /> : null;
	}
}

export default FluidImage