import React, { useEffect, useContext, useState } from "react";
import { Link } from 'gatsby';
import FluidImage from "../fluidImage";
import FixedImage from "../fixedImage";
import ThemeContext from '../../context/ThemeContext';
import WPLink from "../wpLink";
import styles from "./styles.module.scss";
// SVG
import arrowIconOrange from '../../images/svg-icons/arrow-bright-orange.svg'

const NearestDentist = ({locations, dentists, arrowGrayLocal, arrowRedLocal, inMegaMenu}) => {
	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestDoc, setNearestDoc ] = useState(null);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});

			setNearestLoc(locationList[0]);
			
			if( locationList[0].ACFLocation.locationInfo.left.dentists.length > 0 ){
				setNearestDoc(locationList[0].ACFLocation.locationInfo.left.dentists[0]);
			} else {
				setNearestLoc(null);
				setNearestDoc(dentists[0]);
			}

			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		if( location ){
			sortByDistance();
		} 
	}, [location, locations, dentists, getLocation])

	useEffect( () => {
		getLocation();
	}, [getLocation] )

  	return (
		<div className={`${styles.NearestDentist} ${!inMegaMenu ? styles.inlinecontent : ''}`}>
			{ nearestDoc === null && (
				<div className={styles.right}>
					<div className={styles.top}>
						<span className={styles.title}>Loading...</span>
					</div>
				</div>
			)}
			{
				nearestDoc !== null && (
					<>
						<div className={styles.left}>
							{nearestDoc.ACFDentist && nearestDoc.ACFDentist.headshot && (
								<Link to={nearestDoc.link}><FluidImage image={nearestDoc.ACFDentist.headshot} passedClass={styles.image} /></Link>
							)}
						</div>
						<div className={styles.right}>
							<div className={styles.top}>
								<Link to={nearestDoc.link} className={styles.dentistlink}><h3 className={styles.title}>{nearestDoc.title}</h3></Link>
								
								{ nearestDoc && nearestDoc.ACFDentist && nearestDoc.ACFDentist.briefDescription && inMegaMenu && <p dangerouslySetInnerHTML={{__html: nearestDoc.ACFDentist.briefDescription.length > 100 ? nearestDoc.ACFDentist.briefDescription.substring(0, 99) + "..." : nearestDoc.ACFDentist.briefDescription }}></p>}
								{ nearestDoc && nearestDoc.ACFDentist && nearestDoc.ACFDentist.briefDescription && !inMegaMenu && <p className={styles.dentistdescription} dangerouslySetInnerHTML={{__html: nearestDoc.ACFDentist.briefDescription.length > 200 ? nearestDoc.ACFDentist.briefDescription.substring(0, 199) + "..." : nearestDoc.ACFDentist.briefDescription }}></p>}
								<Link to={nearestDoc.link} className={styles.learnmore}>Meet Dr. {nearestDoc.ACFDentist.lastName /* Show first name */} {!inMegaMenu ? <img className={styles.icon} src={arrowIconOrange} alt="" /> : <FixedImage className={styles.icon} image={arrowGrayLocal} />}</Link>
							</div>

							{!inMegaMenu ? null : (
								<div className={styles.bottom}>
									{ nearestLoc && <Link className={styles.locationlink} to={nearestLoc.link} dangerouslySetInnerHTML={{__html: nearestLoc.title}}></Link> }
									{ nearestLoc && nearestLoc.ACFLocation.bookMyAppointmentLink && <WPLink link={nearestLoc.ACFLocation.bookMyAppointmentLink} passedClass={styles.link} icon={arrowIconOrange} iconIsSVG={true} />}
									{ nearestLoc && !nearestLoc.ACFLocation.bookMyAppointmentLink && nearestLoc.birdeyeLocation.phone && <a className={styles.link} href={`tel:${nearestLoc.birdeyeLocation.phone}`}>Call Now</a>}
								</div>
								)
							}
						</div>
					</>
				)
			}
		</div>
  	)
}

export default NearestDentist;
