import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import FLuidImage from "../fluidImage";
import WPLink from "../wpLink";
import FixedImage from "../fixedImage";
import Submenu from "../submenu";
import MobileNav from "../mobileNav";
import DesktopNav from "../desktopNav";
import formatPhone from "../../helpers/formatPhone";
import styles from "./styles.module.scss";

const Header = ({hideCtaButton = false, headerNavHasDarkTheme, isHomepage, hideMobileCtaButton = false}) => {

	const data = useStaticQuery(graphql`
		query HeaderQuery {
			options: wp {
				headerSection {
				  	ACFHeaderOptions {
						topMenuBar {
							topPhoneNumber
							appointmentLink {
								... on WpPage {
									link
								}
								... on WpPost {
									link
								}
								... on WpMediaItem {
									link
								}
								... on WpLocation {
									link
								}
								... on WpDentist {
									link
								}
								... on WpFaq {
									link
								}
								... on WpInsurance {
									link
								}
								... on WpService {
									link
								}
								... on WpReview {
									link
								}
							}
							appointmentLinkText
							disclaimerInfo
							insuranceMegamenuText
							noInsuranceText
							noInsuranceSettings {
								content {
									noInsuranceBlurb
									noInsuranceHeading
									noInsuranceLink {
										url
										title
										target
									}
								}
								list {
									noInsuranceListHeading
									noInsuranceList {
										listItem
									}
								}
					  		}
							selectedInsurances {
								... on WpInsurance {
									title
									ACFInsurance {
										logo {
											altText
											localFile {
												childImageSharp {
													fluid {
														...GatsbyImageSharpFluid_withWebp
													}
												}
												publicURL
                								extension
											}
										}
									}
								}
							}
						}
						mainNavigationSettings {
							mobileNavigationLogo {
								altText
								localFile {
									childImageSharp {
										fixed(width: 154) {
											...GatsbyImageSharpFixed_withWebp
										}
									}
									publicURL
                					extension
								}
							}
							showLocationsInMenu
							showServicesInMenu 
							showTeamInMenu
							servicesMegamenu {
								featuredServices {
									... on WpService {
										link
										title
										id
										ACFService {
											briefDescription
											serviceIcon {
												altText
												localFile {
													childImageSharp {
														fluid {
															...GatsbyImageSharpFluid_withWebp
														}
													}
													publicURL
                									extension
												}
											}
										}
									}
								}
							}
							servicesMenuTitle
							navCta {
								url
								title
								target
							}
							links {
								link {
									target
									title
									url
								}
							}
							teamMegamenu {
								featuredTeamMember {
									... on WpDentist {
										id
										title
										link
									}
								}
							}
							mobileNavLinks {
								mobileLinkItem {
									target
									title
									url
								}
							}
							locationsMenuTitle
							teamMenuTitle
						}
				  	}
				}
				socialSection {
					ACFSocial {
					  facebookUrl
					  instagramUrl
					  tiktokUrl
					}
				}
			}
			services: allWpService(sort: {fields: title, order: ASC}) {
				nodes {
				  id
				  title
				  link
				}
			}
			locations: allWpLocation(sort: {fields: title, order: ASC}) {
				nodes {
					id
					title
					link
					birdeyeLocation {
						location {
							city
							state
							address1
							address2
							zip
							lat
							lng
						}
						phone
					}
					ACFLocation {
						bookMyAppointmentLink {
							url
							title
							target
						}
						menuTitle
						briefDescription
						locationInfo {
							left {
								dentists {
									... on WpDentist {
										id
										ACFDentist {
											lastName
											briefDescription
											headshot {
												localFile {
													childImageSharp {
														fluid(maxWidth: 280){
															...GatsbyImageSharpFluid_withWebp
														}
													}
												}
											}
										}
										title
										link
									}
								}
							}
						}
					}
				}
			}
			dentists: allWpDentist(sort: {fields: title, order: ASC}) {
				nodes {
				  id
				  title
				  link
					ACFDentist {
						headshot {
							localFile {
								childImageSharp {
									fluid(maxWidth: 280){
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
			smileIcon: file(name: {eq: "smile_icon"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			paymentIcon: file(name: {eq: "payment_icon"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			badgeIcon: file(name: {eq: "badge_icon"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowRed: file(name: {eq: "arrow_offsite_red"}) {
				childImageSharp {
				  fixed(width: 8) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowRedLocal: file(name: {eq: "arrow_red_local"}) {
				childImageSharp {
				  fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowGrayLocal: file(name: {eq: "arrow_gray_local"}) {
				childImageSharp {
				  fixed(width: 10) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowBlue: file(name: {eq: "arrow_blue"}) {
				childImageSharp {
				  fixed(width: 9) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			menuIcon: file(name: {eq: "menu_icon"}) {
				childImageSharp {
				  fixed(width: 20) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			closeIcon: file(name: {eq: "close_icon"}) {
				childImageSharp {
				  fixed(width: 16) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			phoneIcon: file(name: {eq: "phone_icon"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}

			facebook: file(name: {eq: "facebook"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			instagram: file(name: {eq: "instagram"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			tiktok: file(name: {eq: "tiktok"}) {
				childImageSharp {
				  fixed(width: 15) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			locationsPage: allWpPage(limit: 1, filter: {template: {templateName: {eq: "Locations Page"}}}) {
				nodes {
				  link
				}
			}
			teamPage: allWpPage(limit: 1, filter: {template: {templateName: {eq: "Team Page"}}}) {
				nodes {
				  link
				}
			}
		}
	`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					topMenuBar: {
						topPhoneNumber,
						appointmentLink: {
							link: appointmentLink
						},
						appointmentLinkText,
						insuranceMegamenuText,
						disclaimerInfo: insuranceDisclaimer,
						selectedInsurances,
						noInsuranceText,
						noInsuranceSettings: {
							content: {
								noInsuranceBlurb,
								noInsuranceHeading,
								noInsuranceLink
							},
							list: {
								noInsuranceListHeading,
								noInsuranceList
							}
						},
					},
					mainNavigationSettings
				}
			},
			socialSection: {
				ACFSocial: {
				  facebookUrl,
				  instagramUrl,
				  tiktokUrl,
				}
			}
		},
		services: {
			nodes: serviceNodes
		},
		dentists: {
			nodes: dentistNodes
		},
		locations: {
			nodes: locationNodes
		},
		locationsPage,
		teamPage,
		smileIcon,
		paymentIcon,
		badgeIcon,
		arrowRed,
		arrowBlue,
		menuIcon,
		closeIcon,
		arrowRedLocal,
		arrowGrayLocal,
		phoneIcon,
		facebook,
		tiktok,
		instagram
	} = data;

	const [ insuranceIsOpen, setInsuranceIsOpen ] = useState(false);
	const [ noInsuranceIsOpen, setNoInsuranceIsOpen ] = useState(false);

	const toggleLinkTarget = useRef();

	const toggleOpenSubmenu = async (event, menu) => {
		toggleLinkTarget.current = event.target;
		switch(menu){
			case 'insurance':
				if( noInsuranceIsOpen ){
					await setNoInsuranceIsOpen(false);
				}
				const insuranceCurrentSetting = insuranceIsOpen;
				await setInsuranceIsOpen(!insuranceCurrentSetting);
				break;
			case 'noinsurance':
				if( insuranceIsOpen ){
					await setInsuranceIsOpen(false);
				}
				const noInsuranceCurrentSetting = noInsuranceIsOpen;
				await setNoInsuranceIsOpen(!noInsuranceCurrentSetting);
				break;
			default:
				break;
		}
	};
	
	return(
		<header className={styles.Header}>
			<div className={styles.top}>
				<ul className={styles.topmenu}>
					<li className={styles.topmenuitemfirst}>
						{ appointmentLink && (
							<Link className={styles.toggle} to={appointmentLink}>
								{ badgeIcon && <Img className={styles.icon} fixed={badgeIcon.childImageSharp.fixed} /> }
								{ appointmentLinkText && <div dangerouslySetInnerHTML={{__html: appointmentLinkText}}></div> }
							</Link> 
						)}
					</li>
					<li className={styles.topmenuitem}>
						{ insuranceMegamenuText && (
							<button className={styles.toggle} onClick={ (event) => { toggleOpenSubmenu(event, 'insurance') }}>
								{ paymentIcon && <Img className={styles.icon} fixed={paymentIcon.childImageSharp.fixed} /> }
								<span className={styles.text}>{insuranceMegamenuText}</span>
							</button>
						)}
						<button aria-label="close modal" className={  insuranceIsOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { setInsuranceIsOpen(false); }} onKeyDown={(event) => { if (event.keycode === 13){ setInsuranceIsOpen(false); }}} tabIndex={0}></button> 
						<Submenu showIf={insuranceIsOpen} changeState={setInsuranceIsOpen} passedClass={styles.insurances}>
							<div className={styles.inner}>
								{ insuranceDisclaimer && <div className={styles.disclaimer} dangerouslySetInnerHTML={{__html: insuranceDisclaimer}}></div> }
								<div className={styles.top}>
									{ selectedInsurances && (
										<ul className={styles.list}>
											{ selectedInsurances.map( (insurance, index) => {
												return <li className={styles.item} key={`insurance-${index}`}>{insurance.title}</li>;
											})}
										</ul>
									)}
								</div>
								<div className={styles.bottom}>
									{ selectedInsurances && selectedInsurances.map( (insurance, index) => {
										const image = insurance.ACFInsurance.logo;
										return image ? <FLuidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
									})}
								</div>
							</div>
						</Submenu>
					</li>
					<li className={styles.topmenuitemlast}>
						{ noInsuranceText && (
							<button className={styles.toggle} onClick={ (event) => { toggleOpenSubmenu(event, 'noinsurance') }}>
								{ paymentIcon && <Img className={styles.icon} fixed={smileIcon.childImageSharp.fixed} /> }
								<span className={styles.text}>{noInsuranceText}</span>
							</button>
						)}
						<button aria-label="close modal" className={  noInsuranceIsOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { setNoInsuranceIsOpen(false); }} onKeyDown={(event) => { if (event.keycode === 13){ setNoInsuranceIsOpen(false); }}} tabIndex={0}></button> 
						<Submenu showIf={noInsuranceIsOpen} changeState={setNoInsuranceIsOpen} passedClass={styles.noinsurance}>
							<div className={styles.inner}>
								<div className={styles.left}>
									{ noInsuranceHeading && <span className={styles.heading}>{noInsuranceHeading}</span> }
									{ noInsuranceBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: noInsuranceBlurb}}></div> }
									{ noInsuranceLink && <WPLink link={noInsuranceLink} passedClass={styles.link} icon={arrowRed} /> }
								</div>
								<div className={styles.right}>
									{ noInsuranceListHeading && <span className={styles.heading}>{noInsuranceListHeading}</span> }
									{ noInsuranceList && (
										<ul className={styles.list}>
											{ noInsuranceList.map( (item, index) => {
												return <li key={`noilistitem-${index}`} className={styles.listitem}><FixedImage passedClass={styles.icon} image={arrowBlue} /> {item.listItem}</li>
											})}
										</ul>
									)} 
									
								</div>
							</div>
						</Submenu>
					</li>
				</ul>
				<ul className={styles.toprightmenu}>
					{ topPhoneNumber && (
						<li className={styles.phone}><a className={styles.link} href={`tel:${topPhoneNumber}`} target="_blank" rel="noopener noreferrer"><FixedImage image={phoneIcon} passedClass={styles.icon} /> {formatPhone(topPhoneNumber)}</a></li>
					)}
					{ facebookUrl && (
						<li className={styles.social}><a href={facebookUrl} className={styles.link} target="_blank" rel="noopener noreferrer"><FixedImage image={facebook} passedClass={styles.facebook} /><span>Facebook</span></a></li>
					)}
					{ tiktokUrl && (
						<li className={styles.social}><a href={tiktokUrl} className={styles.link} target="_blank" rel="noopener noreferrer"><FixedImage image={tiktok} passedClass={styles.twitter} /><span>TikTok</span></a></li>
					)}
					{ instagramUrl && (
						<li className={styles.social}><a href={instagramUrl} className={styles.link} target="_blank" rel="noopener noreferrer"><FixedImage image={instagram} passedClass={styles.instagram} /><span>Instagram</span></a></li>
					)}
				</ul>
			</div>
			<MobileNav mainNavigationSettings={mainNavigationSettings} menuIcon={menuIcon} closeIcon={closeIcon} services={serviceNodes} locations={locationNodes} dentists={dentistNodes} arrowBlue={arrowBlue} hideMobileCtaButton={hideMobileCtaButton} />
			<div className={styles.stickycontainer}>
				<DesktopNav 
					isHomepage={isHomepage}
					headerNavHasDarkTheme={headerNavHasDarkTheme}
					hideCtaButton={hideCtaButton}
					mainNavigationSettings={mainNavigationSettings} 
					services={serviceNodes} 
					locations={locationNodes} 
					dentists={dentistNodes} 
					arrowBlue={arrowBlue} 
					arrowGrayLocal={arrowGrayLocal}
					arrowRedLocal={arrowRedLocal} 
					locationsPage={locationsPage} 
					teamPage={teamPage} 
				/>
			</div>
		</header>
	);
}

export default Header;
