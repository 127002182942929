import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import FixedImage from "../fixedImage";
import FLuidImage from "../fluidImage";
import WPLink from "../wpLink";
import Submenu from "../submenu";
import styles from "./styles.module.scss";

const ModalLink = ({ modal, link, passedClass = styles.default,  icon = null }) => {

	const data = useStaticQuery(graphql`
		query {
			options: wp {
				headerSection {
					ACFHeaderOptions {
						topMenuBar {
							disclaimerInfo
							insuranceMegamenuText
							noInsuranceText
							noInsuranceSettings {
								content {
									noInsuranceBlurb
									noInsuranceHeading
									noInsuranceLink {
										url
										title
										target
									}
								}
								list {
									noInsuranceListHeading
									noInsuranceList {
										listItem
									}
								}
							}
							selectedInsurances {
								... on WpInsurance {
									title
									ACFInsurance {
										logo {
											localFile {
												childImageSharp {
													fluid {
														...GatsbyImageSharpFluid_withWebp
													}
												}
												publicURL
                								extension
											}
										}
									}
								}
							}
						}
					}
				}
			}
			arrowRed: file(name: {eq: "arrow_offsite_red"}) {
				childImageSharp {
				  fixed(width: 8) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
			arrowBlue: file(name: {eq: "arrow_blue"}) {
				childImageSharp {
				  fixed(width: 9) {
					...GatsbyImageSharpFixed_withWebp
				  }
				}
			}
		}
	`);

	const {
		options: {
			headerSection: {
				ACFHeaderOptions: {
					topMenuBar: {
						disclaimerInfo: insuranceDisclaimer,
						selectedInsurances,
						noInsuranceSettings: {
							content: {
								noInsuranceBlurb,
								noInsuranceHeading,
								noInsuranceLink
							},
							list: {
								noInsuranceListHeading,
								noInsuranceList
							}
						},
					},
				}
			},
		},
		arrowRed,
		arrowBlue
	} = data;

	const [ modalIsOpen, setModalIsOpen ] = useState(false);

	const toggleModal = () => {
		const toggled = !modalIsOpen;
		setModalIsOpen(toggled);
	};
	
  	return (
		<div className={styles.ModalLink}>
			{ link.title && (
				<button className={passedClass} onClick={ () => { toggleModal() }}>
					{link.title}
					{ icon && <FixedImage image={icon} passedClass={styles.icon} /> }
				</button>
			)}
			<button aria-label="close modal" className={  modalIsOpen ? `${styles.shroud} ${styles.shroudopen}` : `${styles.shroud}`}  onClick={() => { toggleModal(); }} onKeyDown={(event) => { if (event.keycode === 13){ toggleModal(); }}} tabIndex={0}></button> 
			{ modal === "/modal/insurance-modal/" && (
				<Submenu showIf={modalIsOpen} setInsuranceIsOpen={setModalIsOpen} passedClass={styles.insurances} orientation="flipped">
				
						<div className={styles.inner}>
							{ insuranceDisclaimer && <div className={styles.disclaimer} dangerouslySetInnerHTML={{__html: insuranceDisclaimer}}></div> }
							<div className={styles.top}>
								{ selectedInsurances && (
									<ul className={styles.list}>
										{ selectedInsurances.map( (insurance, index) => {
											return <li className={styles.item} key={`insurance-${index}`}>{insurance.title}</li>;
										})}
									</ul>
								)}
							</div>
							<div className={styles.bottom}>
								{ selectedInsurances && selectedInsurances.map( (insurance, index) => {
									const image = insurance.ACFInsurance.logo;
									return image ? <FLuidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
								})}
							</div>
						</div>
				</Submenu>
			)}
			{ modal === "/modal/no-insurance-modal/" && (
				<Submenu showIf={modalIsOpen} setInsuranceIsOpen={setModalIsOpen} passedClass={styles.noinsurance} orientation="flipped">
					{ modal === "/modal/no-insurance-modal/" && (
						<div className={styles.inner}>
							<div className={styles.left}>
								{ noInsuranceHeading && <span className={styles.heading}>{noInsuranceHeading}</span> }
								{ noInsuranceBlurb && <div className={styles.blurb} dangerouslySetInnerHTML={{__html: noInsuranceBlurb}}></div> }
								{ noInsuranceLink && <WPLink link={noInsuranceLink} passedClass={styles.link} icon={arrowRed} /> }
							</div>
							<div className={styles.right}>
								{ noInsuranceListHeading && <span className={styles.heading}>{noInsuranceListHeading}</span> }
								{ noInsuranceList && (
									<ul className={styles.list}>
										{ noInsuranceList.map( (item, index) => {
											return <li key={`noilistitem-${index}`} className={styles.listitem}><FixedImage passedClass={styles.icon} image={arrowBlue} /> {item.listItem}</li>
										})}
									</ul>
								)} 
								
							</div>
						</div>
					)}
				</Submenu>
			)}
		</div>
  	)
}

export default ModalLink;